@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.categoryCardHeader {
  @include spacingAround(0);
  @include spacingLeft(1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--category-overview-card-header-text-color);

  @media all and (min-width: $tablet) {
    border-bottom: 2px solid var(--category-overview-card-header-border-bottom-color);
    cursor: default;
  }

  @media all and (min-width: $desktop) {
    @include spacingLeft(0);
  }

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacingCalculator(1);

    @include FontPrimaryBold;
    font-size: var(--font-size-text);
    word-break: break-all;
    line-height: 1.4;

    a {
      color: inherit;
    }
  }

  .categoryIcon {
    width: 50px;
    height: 50px;
    background-color: var(--category-overview-card-header-text-color);
    mask-size: contain;
  }
}
