@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.productPrice {
  --product-price-detail-label-color: var(--font-color-light);

  display: grid;

  .priceInfoColumn {
    @include FontPrimaryRegular;
    font-size: var(--font-size-text-small);
    line-height: 20px;
    color: var(--product-price-detail-label-color);
    justify-content: flex-end;
    align-items: end;

    @media all and (min-width: $tablet) {
      display: flex;
    }

    &.onlyDesktop {
      display: none;

      @media all and (min-width: $tablet) {
        display: grid;
      }
    }
  }

  .priceInfoButton {
    display: flex;
    gap: spacingCalculator(1);
    align-items: center;

    .detailsLabel {
      text-decoration: underline;
      padding-right: 3px;
      cursor: default;
    }
  }
}
